






































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ProjectModule, Project, Lab, Department, Section, ProjectTag, MainTheme } from '@/store/projects';
import { ApiHelper } from '@/ApiHelper';
import { AppModule } from '@/store/app';
import Comments from '@/components/Comments.vue'
import { CommentModule, Comment } from '@/store/comments';
import CInput from '@/components/CInput.vue'
import { v4 as uuidv4 } from 'uuid';
import { required } from 'vee-validate/dist/rules';
import { extend, ValidationObserver} from 'vee-validate';
//import ja from 'vee-validate/dist/locale/ja.json';

extend('required', required);

@Component <ProjectView> ({
  components: {
    Comments,
    ValidationObserver,
    CInput
  },
  head: {
    title() {
      return { inner: this.documentTitle }
    },
    meta() {
      return [
        { property: 'keyword', content: this.keyword + ',なわてん,大阪電気通信大学,四條畷キャンパス,卒業研究,卒業制作展,総合情報学部,デジタルゲーム学科,情報学科,医療健康科学部', id: 'metakeyword'},
        { property: 'description', content: this.project.excerpt, id: 'metadescription'},
        { property: 'og:title', content: this.documentTitle, id:'ogtitle' },
        { property: 'og:type', content: 'website', id: 'ogtype' },
        { property: 'og:description', content: this.project.excerpt, id: 'ogdescription'},
        { property: 'og:url', content: `${location.href}`, id: 'ogurl' },
        { property: 'og:image', content: (this.project.eyecatch ? this.project.eyecatch : 'https://www.nawaten.online/img/ogp.png'), id:'ogimage' },
        { property: 'twitter:card', content: 'summary_large_image', id:'twittercard' },
        { property: 'twitter:site', content: '@nawaten_info', id: 'twittersite' },
      ];
    },
  },
})
export default class ProjectView extends Vue {
  private project: Project = {} as Project;
  private comments = [];
  private commentContent = '';
  private authorName = '';
  private authorEmail = '';
  private sendError = '';
  private replyId = 0;
  private replyTo = {} as Comment;
  private uid = null as string | null;
  private isVoted = false;
  private documentTitle = '';
  private keyword = '';

  private get commentIsReady() {
    return this.commentContent != '' && this.authorName != '';
  }
  private created() {
    AppModule.setLoaded(false);
    this.uid = localStorage.getItem('uid')
    if(!this.uid){
      this.uid = uuidv4();
      localStorage.setItem('uid', this.uid);
    }
  }

  private async mounted(){
    ApiHelper.callApi(`/wp-json/nawatenpolls/isvoted?post_id=${this.$route.params.id}&uid=${this.uid}`, 'get', {}, res => {
      this.isVoted = res.data.isVoted;
    });
    await ApiHelper.callApi(`/wp-json/wp/v2/project/${this.$route.params.id}?_embed`, 'get', {}, res => {
      this.project = res.data as Project;
      this.project.eyecatch = res.data['_embedded']["wp:featuredmedia"] ? res.data['_embedded']["wp:featuredmedia"][0]["media_details"]['sizes']['full']['source_url'] : "";
      this.project.creators = res.data.acf.creator;
      this.project.labs = [];
      this.project.sections = [];
      this.project.departments = [];
      this.project.projectTags = [];
      this.project.mainThemes = [];
      for(const taxonomies of res.data['_embedded']["wp:term"]){
        for(const taxonomy of taxonomies){
          switch(taxonomy.taxonomy){
            case 'department':
              this.project.departments.push(taxonomy as Department);
              break;
            case 'lab':
              this.project.labs.push(taxonomy as Lab);
              break;
            case 'section':
              this.project.sections.push(taxonomy as Section);
              break;
            case 'main_theme':
              this.project.mainThemes.push(taxonomy as MainTheme);
              break;
            case 'project_tag':
              this.project.projectTags.push(taxonomy as ProjectTag);
              break;
          }
        }
      }
      let termName = '';
      if ( this.project.departments ){
        termName = this.project.departments[0].name;
      } else if( this.project.labs ){
        termName = this.project.labs[0].name;
      } else if( this.project.sections ){
        termName = this.project.sections[0].name;
      } else if( this.project.mainThemes ){
        termName = this.project.mainThemes[0].name;
      } else if( this.project.projectTags ){
        termName = this.project.projectTags[0].name;
      }
      this.keyword = res.data.title.rendered;
      this.documentTitle = res.data.title.rendered + (termName ? ' | ' + termName : '') + ' | ' + res.data["blog_title"];
      this.$emit('updateHead');
    });
    this.getComment();
    await ApiHelper.callApi(`/wp-json/wordpress-popular-posts/v1/popular-posts/`, 'post', {
      'wpp_id': this.$route.params.id
    }, res => {
      //
    });

    AppModule.setLoaded(true);
  }
  private getComment(){
     ApiHelper.callApi(`/wp-json/wp/v2/comments/?post=${this.$route.params.id}&per_page=100`, 'get', {}, res => {
      const nest = (items: any, id = 0) =>
        items
          .filter((item: any) => item.parent === id)
          .map((item: any) => ({ ...item, children: nest(items, item.id) }));
      this.comments = nest(res.data);
    });
  }

  private send(){

    this.sendError = '';

    ApiHelper.callApi(`/wp-json/wp/v2/comments/`, 'post', {
      'post': this.project.id,
      'parent': this.replyId,
      'content': this.commentContent,
      'author_name': this.authorName,
      'author_email': this.authorEmail,
    }, res => {
      this.sendError = "送信しました";
      this.commentContent = "";
      this.getComment();
    }, err => {
      this.sendError = err.response.data.message;
    });

  }
  private toReply(reply: Comment) {
    const form = document.getElementById("commentform_box") as HTMLElement;
    const formrect = form.getBoundingClientRect();

    window.scrollBy({
      top: formrect.top,
      left: formrect.left,
      behavior: "smooth"
    });

    this.replyId = reply.id;
    this.replyTo = reply;
  }

  private vote(){
    ApiHelper.callApi(`/wp-json/nawatenpolls/vote`, 'post', {
      'post_id': this.$route.params.id,
      'uid': this.uid,
    }, res => {
      this.isVoted = true;
    });
  }
  private unvote(){
    ApiHelper.callApi(`/wp-json/nawatenpolls/unvote`, 'post', {
      'post_id': this.$route.params.id,
      'uid': this.uid,
    }, res => {
      this.isVoted = false;
    });
  }
}
