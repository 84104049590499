








































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ProjectModule, Project } from '@/store/projects';
import { ApiHelper } from '@/ApiHelper';
import Projects from '@/components/Projects.vue';
import { AppModule } from '@/store/app';

@Component <ProjectsView> ({
  components: {
    Projects
  },
  head: {
    title() {
      return { inner: this.documentTitle }
    },
    meta() {
      return [
        { property: 'keyword', content: 'なわてん,大阪電気通信大学,四條畷キャンパス,卒業研究,卒業制作展,総合情報学部,デジタルゲーム学科,情報学科,医療健康科学部', id: 'metakeyword'},
        { property: 'description', content: 'なわてん - 2022年度 大阪電気通信大学 四條畷キャンパス 卒業研究・卒業制作展 総合情報学部、デジタルゲーム学科、情報学科、医療健康科学部 2023年2月11日（土）、12日（日）', id: 'metadescription'},
        { property: 'og:title', content: this.documentTitle, id:'ogtitle' },
        { property: 'og:type', content: 'website', id: 'ogtype' },
        { property: 'og:description', content: 'なわてん - 2022年度 大阪電気通信大学 四條畷キャンパス 卒業研究・卒業制作展 総合情報学部、デジタルゲーム学科、情報学科、医療健康科学部 2023年2月11日（土）、12日（日）', id: 'ogdescription'},
        { property: 'og:url', content: document.URL, id: 'ogurl' },
        { property: 'og:image', content: document.URL + 'img/ogp.png', id:'ogimage' },
        { property: 'twitter:card', content: 'summary_large_image', id:'twittercard' },
        { property: 'twitter:site', content: '@nawaten_info', id: 'twittersite' },
      ];
    },
  },
})

// wordpress rest api
// テーマ別
//dev.nawaten.online/wp-json/wp/v2/project/?project_tag=5
// 研究室別
//dev.nawaten.online/wp-json/wp/v2/project/?lab=5
// 学科別
//dev.nawaten.online/wp-json/wp/v2/project/?department=5
// キーワード検索
//dev.nawaten.online/wp-json/wp/v2/project?search=概要


export default class ProjectsView extends Vue {
  private total = 0;
  private totalpages = 0;
  private currentpage = 1;
  private routename = '';
  private eyecatch = '';
  private title = '';
  private documentTitle = '';

  private created() {
    AppModule.setLoaded(true);
  }
  private mounted(){
    this.handlePage();
  }

  public handlePage() {

    // タクソノミー情報
    this.routename = this.$route.name ? this.$route.name : "Section"; // 例外について後で考える
    let taxonomy = this.$route.name ? this.$route.name.toLowerCase() : "section";
    if(taxonomy == 'projecttag') taxonomy = 'project_tag';
    if(taxonomy == 'maintheme') taxonomy = 'main_theme';
    ApiHelper.callApi(`wp-json/wp/v2/${taxonomy}/`+this.$route.params[taxonomy], 'get', {}, res => {
      this.title = res.data.name;
      this.documentTitle = res.data.name + ' | ' + res.data["blog_title"];
      this.$emit('updateHead');
    });

    this.currentpage = this.$route.params.page ? Number(this.$route.params.page) : 1;
    let path = `wp-json/wp/v2/project?per_page=12&page=${this.currentpage}&_embed`;
    const pathtmp = `${path}`;

    if(this.$route.params.lab) path = `${path}&lab=${this.$route.params.lab}`;
    if(this.$route.params.department) path = `${path}&department=${this.$route.params.department}`;
    if(this.$route.params.section) path = `${path}&section=${this.$route.params.section}`;
    if(this.$route.params.main_theme) path = `${path}&main_theme=${this.$route.params.main_theme}`;
    if(this.$route.params.project_tag) path = `${path}&project_tag=${this.$route.params.project_tag}`;

    ApiHelper.callApi(path, 'get', {}, res => {
      ProjectModule.setProjects(res.data.map((v: any)=>{
        return {
          id: v.id,
          title: v.title.rendered as string,
          eyecatch: v.featured_image_url as string,
          terms: v._embedded['wp:term'] ? v._embedded['wp:term'].flat() : [],
          squareEyecatch: v.acf.squareEyecatch.url as string,
        } as Project;
      }));
      if (path == pathtmp){
        this.title = '作品一覧';
        this.documentTitle = '作品一覧' + this.documentTitle;
        this.$emit('updateHead');
      }
      this.total = parseInt(res.headers["x-wp-total"]);
      this.totalpages = parseInt(res.headers["x-wp-totalpages"]);

    });
  }
}
