





























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { LabModule, Lab } from '@/store/labs';
import { ApiHelper } from '@/ApiHelper';
import Labs from '@/components/Labs.vue';
import { AppModule } from '@/store/app';

@Component <LabsView> ({
  components: {
    Labs
  },
  head: {
    title() {
      return { inner: this.documentTitle }
    },
    meta() {
      return [
        { property: 'keyword', content: 'なわてん,大阪電気通信大学,四條畷キャンパス,卒業研究,卒業制作展,総合情報学部,デジタルゲーム学科,情報学科,医療健康科学部', id: 'metakeyword'},
        { property: 'description', content: 'なわてん - 2022年度 大阪電気通信大学 四條畷キャンパス 卒業研究・卒業制作展 総合情報学部、デジタルゲーム学科、情報学科、医療健康科学部 2023年2月11日（土）、12日（日）', id: 'metadescription'},
        { property: 'og:title', content: this.documentTitle, id:'ogtitle' },
        { property: 'og:type', content: 'website', id: 'ogtype' },
        { property: 'og:description', content: 'なわてん - 2022年度 大阪電気通信大学 四條畷キャンパス 卒業研究・卒業制作展 総合情報学部、デジタルゲーム学科、情報学科、医療健康科学部 2023年2月11日（土）、12日（日）', id: 'ogdescription'},
        { property: 'og:url', content: document.URL, id: 'ogurl' },
        { property: 'og:image', content: document.URL + 'img/ogp.png', id:'ogimage' },
        { property: 'twitter:card', content: 'summary_large_image', id:'twittercard' },
        { property: 'twitter:site', content: '@nawaten_info', id: 'twittersite' },
      ];
    },
  },
})

export default class LabsView extends Vue {
  private routename = '';
  private eyecatch = '';
  private title = '研究室一覧';
  private documentTitle = '研究室一覧';

  private created() {
    AppModule.setLoaded(true);
  }
  private mounted(){
    this.handlePage();
  }

  public handlePage() {
    ApiHelper.callApi(`wp-json/wp/v2/lab?per_page=50`, 'get', {}, res => {
      LabModule.setLabs(res.data.map((v: any)=>{
        return {
          id: v.id,
          name: v.name.replace('（','\n（') as string,
          eyecatch: v.acf.eyecatch ? v.acf.eyecatch.url : "" as string,
          squareEyecatch: v.acf.squareEyecatch ? v.acf.squareEyecatch.url : "" as string,
        } as Lab;
      }));
    });
  }
}
